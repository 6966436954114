.psMainMenu{
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 7.5vh;
}

.psMenuButtom{
    position: relative;
    justify-content: center;
    align-items: center; 
    font-size: 1.5vh;
    color: var(--primary-color);
    height: 90%;
    width: 5vh;
    margin: 0.1vh;
    top: 8%;
}

.psButtom{
    background-color: transparent;
    border: 0;
}

.psButtom:hover{
    scale: 1.17; 
}

.psButtom:active{
    scale: 1;
}

.psButtom:disabled{
    scale: 1;
    opacity: 0.7;
}

.psMenuIcon{
     /* padding: 0.5vw; */
     margin-top: 0;
     margin-bottom: auto;
     font-size: 2.6vh;
     height: 4vh;
     width: 3vh;
     /* border: 1px solid red; */
}

.psMenuText{
        /* position: absolute; */
        font-size: 1vh;
        font-weight: bold;
        /* bottom: 0; */
}

.psTableHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3vh;
    color: white;
    background-color: var(--primary-color);
    padding: 1vh;
    font-weight: bold;
    align-items: center;
    font-size: 1.6vh;
    z-index: 9999;
}

.psHeadElement{
    /* position: relative; */
    /* width: 5%; */
    text-align: center;
}

.psAlert{
    position: absolute;
    top: -12%; /* Alinha ao topo */
    right: 5%; /* Alinha à direita */
    background-color: red; /* Apenas um exemplo de cor de fundo */
    color: white; /* Apenas um exemplo de cor do texto */
    padding: 0.3vh;
    height: 1vh;
    width: 1vh; /* Apenas um exemplo de preenchimento */
    border-radius: 50%; /* Torna o alerta circular */
    font-size: 1.2vh; /* Ajuste o tamanho da fonte conforme necessário */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    
    
}

.psBody{
    /* flex: 14;
    background-color: #F2F2F7;
    min-width: 115vh; */
    overflow: auto;
}

.psLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 1.3vh;
    /* color: white; */
    background-color: white;
    padding: 1vh;
    /* font-weight: bold; */
    align-items: center;
    font-size: 1.6vh;
    border-bottom: 1px solid #d2d2d7;
}

.psLineImp{
    background-color: #f2f2f7;
}

.psSectionHead{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background-color: white;
    padding: 0.5vh 0;
    padding-left: 1vh;
    padding-top: 1.5vh;
    text-align: left;
    font-weight: bold;
    font-size: 1.5vh;
    z-index: 1; 
    border-bottom: 1px solid #d2d2d7;
}

