
.selected-itemSwitch {
    text-align: center;
  }
  
  .controls-containerSwitch {
    --highlight-width: auto;
    --highlight-x-pos: 0;
    padding: 0.25vh;
    display: flex;
    justify-content: space-between;
    margin-right: 1vh;
    margin-left: 1.6vh;
    font-size: 1.6vh;
    align-items: center;
    border-bottom: 1px solid #f2f2f7;
  }
  
  .controlsSwitch {
    display: inline-flex;
    justify-content: space-between;
    /* background: #e2e2e7; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    border-radius: 3vh;
    width: 5.5vh;
    height: 3vh;
    /* padding: 0.25vh; */
    /* margin: auto; */
    overflow: hidden;
    position: relative;
    margin-bottom: 0.2vh;
    margin-top: 0.2vh;
  }

  .controlsSwitch.On {
    background-color: #34C759;
    border: 1px solid #34C759;
    min-height: 3vh;
  }

  .controlsSwitch.Off {
    background-color: #e2e2e7;
    border: 1px solid #e2e2e7;
    min-height: 3vh;
    
  }
  
  .controlsSwitch input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 3.5vh;
    cursor: pointer;
    height: 3.5vh;
    
  }
  
  .segmentSwitch {
    width: 6vh;
    /* min-width: 120px; */
    position: relative;
    text-align: center;
    z-index: 1;

    
  }
  
  .segmentSwitch label {
    cursor: pointer;
    display: block;
    /* font-weight: 700; */
    padding: 0.8vh;
    transition: color 0.5s ease;
    font-size: 0vh;
  }
  
  .segmentSwitch.active label {
    font-size: 0vh;
  }
  
  .controlsSwitch::before {
    content: "";
    background: white;
    border-radius: 1.75vh;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 0;
  }
  
  /* Only allow transitions once component is ready */
  .controlsSwitch.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
  }

  
  