.lista{
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 1vh;
    overflow: auto;
    /* overflow: auto; */
}
.colunas{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.coluna{
    min-width: 45vh;
    max-width: 70vh;
    margin-left: 2%;
    margin-right: 2%;
    width: 48%;

}
section{
    text-align: start;
    font-size: 1.6vh;
    font-weight: bold;
    margin-bottom: 1vh;
    margin-top: 2vh;
    
}
.sectionBox{
    border-radius: 10px;
    border: '1px solid red';
    background-color: white;
    margin-left: 0vh;
    margin-bottom: 3vh
}
.sectionBox2{
    border-radius: 10px;
    border: '1px solid red';
    background-color: white;
    margin-left: 0vh;
    margin-bottom: 1vh
}
.subSection{
    text-align: start;
    font-size: 1.6vh;
    margin-left: 1.5vh;
    margin-bottom: 0.3vh;
    margin-top: -2vh;
    color: gray;
    font-weight: bold;
}