.menuIcon{
    /* padding: 0.5vw; */
    margin-top: 0;
    margin-bottom: auto;
    font-size: 2.6vh;
  }

  .menuText{
    /* position: absolute; */
    font-size: 1.3vh;
    bottom: 0;
  }
  .menuButtom{
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1.5vh;
    color: rgb(0, 122, 254);
    height: 80%;
    align-self: center;
    width: 6vh;
    margin: 1.5vh;
    /* border: 1px solid red; */
  }